.container {
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    height: 759px;
    width: 1131px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.leftSide {
    background-color: #383838;
    height: 100%;
    min-width: 226px;
    display: flex;
    border-radius: 3px 0 0 3px;
    transition: 200ms all;
}

.rightSide {
    background-color: #181818;
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: 200ms all;
}

.chatSection {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 16px 24px 0 24px;
}

.inputArea {
    height: 80px;
    width: 100%;
    padding-right: 27px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}


.navbarSection {
    width: 100%;
    height: 60px;
    display: flex;
}





@media screen and (max-width: 860px) {

    .content {
        background-color: red;
        height: 100%;
        width: 100%;
        border-radius: 0px;
    }
    .leftSide {
        display: none;
    }
}
  

@media screen and (max-width: 600px) {
    .chatSection {
        padding: 16px 16px 0 16px;
    }
    .content {
        height: 100%;
        width: 100%;
        border-radius: 0px;
    }
    .leftSide {
        display: none;
    }
    .inputArea {
        padding-right: 18px;
    }
}
  

@media screen and (max-width: 300px) {
    .chatSection {
        padding: 8px 8px 0 8px;
    }
    .inputArea {
        padding-right: 10px;
    }
}
  