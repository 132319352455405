
.inputSection {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.inputSection > .options {
    margin-right: 16px;
    height: 100%;
    padding-left: 48px;
}

.inputContent {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 16px;
}

.inputContent > input{
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background-color: transparent;
    border: 2px solid #3E3E3E;
    border-radius: 12px;
    padding: 0 16px;
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 15px;
    color: white;
    
}

.inputContent > button{
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    height: 33px;
    width: 33px;
    background: #292929;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 200ms background-color;
}

.inputContent > button > img{
    height: 21px;
    width: 21px;
    object-fit: cover;
}

.inputContent > button:hover{
    background: #3f3f3f;
}


@media screen and (max-width: 600px) {

}

@media screen and (max-width: 380px) {
   
}
  