::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(245, 245, 245, 0);
  }
  
  ::-webkit-scrollbar {
    width: 4px;
    background-color: rgba(255, 255, 255, 0);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    width: 4px;
    border-radius: 3px;
  }
  