.chatContainer {
    width: 100%;
    height: 100%;
}

.chatContent {
    width: 100%;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.messageContent {
    width: 100%;
    height: min-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.messageContent.me {
    justify-content: flex-end;
}

.messageContent.me .userSection  img{
    display: none;
}

.userSection img{
    width: 48px;
    height: 48px;   
    max-width: 48px;
    max-height: 48px;   
    background-color: #AAAAAA;
    border-radius: 12px;
}

.userInfo {
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 5px;
}

.userInfo > span{
    font-weight: 400;
    font-size: 13px;
    color: #AAAAAA;
}


.messageContent.me .textSection{
    margin-left: 0;
}

.messageContent.me .textSection {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.textSection {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.messageContent.me .messages {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 3px;
}

.messages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3px;
}

.messageBox {
    background: #3E3E3E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 2px 20px 20px 2px;
    padding: 7px 18px 7px 12px;
    min-width: min-content;
    max-width: 350px;
    text-align: left;
    position: relative;
    cursor: pointer;
    transition: 200ms all;
}

.messageBox:hover {
    background: #4c4c4c;
}

.messageBox::before {
    position: absolute;
    right: 0;
    bottom: 50%;
    transform: translate(30px,50%);
    content: attr(hour);
    font-weight: 400;
    font-size: 13px;
    color: #AAAAAA;
    transition: 200ms all;
    opacity: 0;
    visibility: hidden;
}

.messageBox:hover::before {
    transform: translate(45px,50%);
    opacity: 1;
    visibility: visible;
}

.messageContent.me .messageBox {
    border-radius: 20px 2px 2px 20px;
    padding: 7px 12px 7px 18px;
    text-align: right;
    background: #1058b0;
}


.messageContent.me .messageBox::before {
    position: absolute;
    left: 0;
    right: auto;
    bottom: 50%;
    transform: translate(-30px,50%);
    opacity: 0;
    visibility: hidden;
}

.messageContent.me .messageBox:hover::before {
    transform: translate(-45px,50%);
    opacity: 1;
    visibility: visible;
}

.chatSep {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 12.5px;
    color: rgba(255, 255, 255,.7);
    padding: 0 50px;
    background: rgb(42, 42, 42);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
}

@media screen and (max-width: 600px) {


    .userSection img{
        width: 33px;
        height: 33px;   
        max-width: 33px;
        max-height: 33px;   
        background-color: #AAAAAA;
        border-radius: 9px;
    }



    .textSection {
        margin-left: 12px;
    }
    .messageBox {
        min-width: min-content;
        max-width: 250px;
        text-align: left;
    }

    .chatContent {
        gap: 30px;
    }

}

@media screen and (max-width: 380px) {
    .messageBox {
        min-width: min-content;
        max-width: 210px;
        text-align: left;
    }
}
  

@media screen and (max-width: 300px) {

    .userSection img{
        display: none;
    }
    .messageBox {
        max-width: 200px;
    }
}
  